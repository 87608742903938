import React from "react"
import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"
import Investments from "../../../../components/body/pages/en-ng/legal/disclaimer/investments"
// src/components/body/pages/en-ng/legal/disclaimer/investments.js
const DisclaimerInvestments = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/disclaimer/investments"}
      title="Disclaimer Investments | Kuda | The Money App for Africans"
    />
    <Investments />
  </Layout>
)

export default DisclaimerInvestments
